/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

// pb with fragments: replace by content of fragment
// https://github.com/gatsbyjs/gatsby/blob/26582d31ab14f7bac6d5738e4245ceca2e6d411d/packages/gatsby-transformer-sharp/src/fragments.js#L6
const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/labopichot_team/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            linkedin
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Blog édité par{" "}
        <a href={`https://labopichot.com`}>
          <strong>{author}</strong> - une entreprise 100% française de
          fabrication d'emballage{" "}
        </a>{" "}
        avec une seule usine de fabrication située dans le magnifique parc du
        Livradois-Forez en Auvergne. {` `}
        {/* <a href={`https://twitter.com/${social.twitter}`}>
          You should follow us on Linkedin
        </a> */}
        <a href={`https://fr.linkedin.com/company//${social.linkedin}`}>
          Suivez-nous sur Linkedin !
        </a>
      </p>
    </div>
  )
}

export default Bio
